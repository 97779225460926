import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Main/Base';
import AssignmentsBase from './components/Layout/Assignment/AssignmentBase';
import BasePage from './components/Layout/BasePage';
import { useAuth } from './AuthContext';
import loadable from '@loadable/component';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const Tasks = loadable(() => import('./components/Main/Tasks/Tasks'));
const Task = loadable(() => import('./components/Main/Tasks/Task'));
const TaskContent = loadable(() => import('./components/Main/Tasks/TaskContent'));
const TaskEdit = loadable(() => import('./components/Main/Tasks/TaskEdit'));
const TaskNew = loadable(() => import('./components/Main/Tasks/TaskNew'));
const TaskSubmissions = loadable(() => import('./components/Main/Tasks/TaskSubmissions'));
const TaskAssignments = loadable(() => import('./components/Main/Tasks/TaskAssignments'));
const Assignments = loadable(() => import('./components/Main/Assignments/Assignments'));
const AssignmentNew = loadable(() => import('./components/Main/Assignments/AssignmentNew'));
const AssignmentDuplicate = loadable(() => import('./components/Main/Assignments/AssignmentDuplicate'));
const Groups = loadable(() => import('./components/Main/Groups/Groups'));
const Group = loadable(() => import('./components/Main/Groups/Group'));
const GroupJoin = loadable(() => import('./components/Main/Groups/GroupJoin'));
const GroupNew = loadable(() => import('./components/Main/Groups/GroupNew'));
const GroupEdit = loadable(() => import('./components/Main/Groups/GroupEdit'));
const GroupAssignments = loadable(() => import('./components/Main/Groups/GroupAssignments'));
const GroupStanding = loadable(() => import('./components/Main/Groups/GroupStanding'));
const Users = loadable(() => import('./components/Main/Users'));
const UserProfile = loadable(() => import('./components/Main/UserProfile'));
const UserProfileEdit = loadable(() => import('./components/Main/UserProfileEdit'));
const PermissionGroups = loadable(() => import('./components/Main/PermissionGroups/PermissionGroups'));
const PermissionGroup = loadable(() => import('./components/Main/PermissionGroups/PermissionGroup'));
const PermissionGroupNew = loadable(() => import('./components/Main/PermissionGroups/PermissionGroupNew'));
const PermissionGroupEdit = loadable(() => import('./components/Main/PermissionGroups/PermissionGroupEdit'));
const Competitions = loadable(() => import('./components/Main/Competitions'));
const Submissions = loadable(() => import('./components/Main/Submissions/Submissions'));
// const Submission = loadable(() => import('./components/Main/Submission'));

const Assignment = loadable(() => import('./components/Assignment/Assignment'));
const AssignmentEdit = loadable(() => import('./components/Assignment/AssignmentEdit'));
const AssignmentTasks = loadable(() => import('./components/Assignment/AssignmentTasks'));
const AssignmentTask = loadable(() => import('./components/Assignment/AssignmentTask'));
const PdfContent = loadable(() => import('./components/Assignment/TaskContent'));
const AssignmentSubmissions = loadable(() => import('./components/Assignment/AssignmentSubmissions'));
const AssignmentSubmission = loadable(() => import('./components/Assignment/AssignmentSubmission'));
const AssignmentSubmitCode = loadable(() => import('./components/Assignment/AssignmentSubmitCode'));
const AssignmentStanding = loadable(() => import('./components/Assignment/AssignmentStanding'));
const AssignmentUsers = loadable(() => import('./components/Assignment/AssignmentUsers'));
const AssignmentStatistics = loadable(() => import('./components/Assignment/AssignmentStatistics'));
const PublicAssignmentStanding = loadable(() => import('./components/Assignment/PublicAssignmentStanding'));


const Login = loadable(() => import('./components/Pages/Login'));
const Game = loadable(() => import('./components/Pages/Game'));
const Register = loadable(() => import('./components/Pages/Register'));
const Recover = loadable(() => import('./components/Pages/Recover'));
const Lock = loadable(() => import('./components/Pages/Lock'));
const NotFound = loadable(() => import('./components/Pages/NotFound'));
const Error500 = loadable(() => import('./components/Pages/Error500'));
const Maintenance = loadable(() => import('./components/Pages/Maintenance'));


// List of routes that uses the page layout
// listed here to Routes between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/recover',
    '/lock',
    '/notfound',
    '/error500',
    '/maintenance',
    '/game'
];

const Router = () => {
    let location = useLocation();

    console.log(location);
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const username = useAuth().username;

    const animationName = 'rag-fadeIn'

    if(!username) {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Routes location={location}>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/game" element={<Game/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/recover" element={<Recover/>}/>
                        <Route path="/lock" element={<Lock/>}/>
                        <Route path="/notfound" element={<NotFound/>}/>
                        <Route path="/error500" element={<Error500/>}/>
                        <Route path="/maintenance" element={<Maintenance/>}/>
                        <Route path="/public/standing/:aid" element={<PublicAssignmentStanding/>}/>
                        <Route path="*" element={<Navigate to='/login' replace/> }/>
                    </Routes>
                </Suspense>
            </BasePage>
        )
    } else if (location.pathname.indexOf("/assignments/new") < 0 &&
            location.pathname.indexOf("/assignments/") > -1) {
                if (location.pathname.indexOf("/pdf")>-1) {
                    return (
                    <Suspense fallback={<PageLoader/>}>
                        <Routes location={location}>
                            <Route path="/assignments/:aid/tasks/:tid/pdf" element={<PdfContent/>}/>
                        </Routes>
                    </Suspense>)
                }
                const aid = location.pathname.split('/')[2];

            return (
                <AssignmentsBase aid={aid} >
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Routes location={location}>
                                    <Route path="/assignments/:aid/tasks/:tid" element={<AssignmentTask/>}/>
                                    <Route path="/assignments/:aid/edit" element={<AssignmentEdit/>}/>
                                    <Route path="/assignments/:aid/tasks" element={<AssignmentTasks/>}/>
                                    <Route path="/assignments/:aid/submitcode" element={<AssignmentSubmitCode/>}/>
                                    <Route path="/assignments/:aid/submissions/:sid" element={<AssignmentSubmission/>}/>
                                    <Route path="/assignments/:aid/submissions" element={<AssignmentSubmissions/>}/>
                                    <Route path="/assignments/:aid/standing" element={<AssignmentStanding/>}/>
                                    <Route path="/assignments/:aid/registered" element={<AssignmentUsers/>}/>
                                    <Route path="/assignments/:aid/statistics" element={<AssignmentStatistics/>}/>
                                    <Route path="/assignments/:aid/home" element={<Assignment/>}/>
                                    <Route path="*" element={<Navigate to={`/assignments/${aid}/tasks`} replace/> }/>
                                </Routes>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                </AssignmentsBase>
            )
    } else {
        if (location.pathname.indexOf("/pdf")>-1) {
            return (
            <Suspense fallback={<PageLoader/>}>
                <Routes location={location}>
                    <Route path="/tasks/:tid/pdf" element={<TaskContent/>}/>
                </Routes>
            </Suspense>)
        } else if (location.pathname.indexOf("/public/standing/")>-1) {
            return (
            <Suspense fallback={<PageLoader/>}>
                <Routes location={location}>
                    <Route path="/public/standing/:aid" element={<PublicAssignmentStanding/>}/>
                </Routes>
            </Suspense>)
        }
        
            return (
                <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Routes location={location}>
                                    <Route path="/groups/:gid/assignments/new/:aid" element={<AssignmentDuplicate/>}/>
                                    <Route path="/groups/:gid/assignments/new" element={<AssignmentNew/>}/>
                                    <Route path="/assignments" element={<Assignments/>}/>
                                    <Route path="/tasks/:tid/edit" element={<TaskEdit/>}/>
                                    <Route path="/tasks/:tid/submissions" element={<TaskSubmissions/>}/>
                                    <Route path="/tasks/:tid/assignments" element={<TaskAssignments/>}/>
                                    <Route path="/tasks/:tid" element={<Task/>}/>
                                    <Route path="/tasks/new/:tid" element={<TaskNew/>}/>
                                    <Route path="/tasks" element={<Tasks/>}/>
                                    <Route path="/groups/:gid/assignments" element={<GroupAssignments/>}/>
                                    <Route path="/groups/:gid/standing" element={<GroupStanding/>}/>
                                    <Route path="/groups/:gid/edit" element={<GroupEdit/>}/>
                                    <Route path="/groups/:gid" element={<Group/>}/>
                                    <Route path="/groups/join" element={<GroupJoin/>}/>
                                    <Route path="/groups/new" element={<GroupNew/>}/>
                                    <Route path="/groups" element={<Groups/>}/>
                                    <Route path="/users/profile/edit" element={<UserProfileEdit/>}/>
                                    <Route path="/users/profile" element={<UserProfile/>}/>
                                    <Route path="/users" element={<Users/>}/>
                                    <Route path="/permissiongroups/:pgid/edit" element={<PermissionGroupEdit/>}/>
                                    <Route path="/permissiongroups/:pgid" element={<PermissionGroup/>}/>
                                    <Route path="/permissiongroups/new" element={<PermissionGroupNew/>}/>
                                    <Route path="/permissiongroups" element={<PermissionGroups/>}/>
                                    {/* <Route path="/submissions/:sid" element={<Submission/>}/> */}
                                    <Route path="/submissions" element={<Submissions/>}/>
                                    {/* <Route path="/competitions" element={<Competitions/>}/> */}
                                    <Route path="*" element={<Navigate to='/groups' replace/> }/>
                                </Routes>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                </Base>
            )
    }
}

export default Router;
