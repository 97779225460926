import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import configureStore from './store/store';
import { AuthProvider } from './AuthContext';
import { RestProvider } from './RestContext';
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <RestProvider>
                <App />
            </RestProvider>
        </AuthProvider>
    </Provider>,
    document.getElementById('app')
);
