import React, { useState } from 'react';
import { sendRequestWithToken } from './rest'
import swal from 'sweetalert';

const AuthContext = React.createContext()

const AuthProvider = ({children}) => {

    const [userrole, setUserrole] = useState(localStorage.getItem("userrole"));
    const [usertoken, setUsertoken] = useState(localStorage.getItem("usertoken"));
    const [names, setNames] = useState(localStorage.getItem("names"));
    const [username, setUsername] = useState(localStorage.getItem("username"));

    async function login(username, password, rememberme) {
        const token = btoa(unescape(encodeURIComponent(`${username}:${password}`)));
        const response = await sendRequestWithToken('me', 'json', token);

        if (!response.ok) {
            logout();
            if (!username) swal("Грешка", "Не сте въвели потребителско име!", "error");
            else if (!password) swal("Грешка", "Не сте въвели парола!", "error");
            else swal("Грешка", "Грешно потребителско име или парола!", "error");
            return;
        }
            
        const user = await response.json();
        if (rememberme) {
            setLocalStorageInfo(user.username, `${user.firstname} ${user.lastname}`, user.role, token);
        } else {
            removeLocalStorageInfo();
        }

        setLocalInfo(user.username, `${user.firstname} ${user.lastname}`, user.role, token);
    }
    
    const setLocalInfo = (username, names, userrole, usertoken) => {
        setNames(names);
        setUserrole(userrole);
        setUsertoken(usertoken);
        setUsername(username);
    }

    const setLocalStorageInfo = (username, names, userrole, usertoken) => {
        localStorage.setItem("names", names);
        localStorage.setItem("userrole", userrole);
        localStorage.setItem("usertoken", usertoken);
        localStorage.setItem("username", username);
    }

    const logout = () => {
        removeLocalInfo();
        removeLocalStorageInfo();
    }

    const removeLocalInfo = () => {
        setUsername(null);
        setNames(null);
        setUserrole(null);
        setUsertoken(null);
    }

    const removeLocalStorageInfo = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("names");
        localStorage.removeItem("userrole");
        localStorage.removeItem("usertoken");
    }

    return (
        <AuthContext.Provider
            value={{
                login: login,
                logout: logout,
                username: username,
                names: names,
                userrole: userrole,
                usertoken: usertoken
            }}>
            {children}
        </AuthContext.Provider>
    )

}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
