import React, { Component } from 'react';

class Footer extends Component {
    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>&copy; 2016-{year} - Направено с <span style={{color: 'rgb(240, 80, 80)'}}>❤</span> от Пешо и Марин в <a href="http://olimpiici.com/">школа Олимпийци</a>.</span>
            </footer>
        );
    }

}

export default Footer;
