import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';
import SidebarLogo from '../SidebarLogo';
import { useAuth } from '../../../AuthContext';
import { useAssignment } from '../../../AssignmentContext';
import PageLoader from '../../Common/PageLoader';
import AssignmentCountdown from '../../Assignment/AssignmentCountdown';
import moment from 'moment';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive, aid}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={`/assignments/${aid}${item.path}`} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

const AssignmentSidebar = ({aid}) => {

    // const { aid } = useParams();
    const location = useLocation();
    const userrole = useAuth().userrole;
    const assignment = useAssignment().assignment;

    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) > -1)
    }

    /** map menu config to string to determine which element to render */
    const itemType = (item) => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    const shouldShowStandingsToUser = () => {
        if(assignment.standings === "never") {
            return false;
        }
        if(assignment.standings === "after" && moment(assignment.endtime).isAfter(moment())) {
            return false;
        }
        return true;
    }

    if (!assignment) return <PageLoader />

    return (
        <aside className='aside-container'>
            { /* START Sidebar (left) */ }
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    { /* START sidebar nav */ }
                    <ul className="sidebar-nav">
                        { /* START user info */ }
                        <Link to="/">
                            <SidebarLogo/>
                        </Link>
                        <AssignmentCountdown />
                        {/* <li className="has-user-block">
                            <SidebarUserBlock/>
                        </li> */}
                        { /* END user info */ }

                        { /* Iterates over all sidebar items */ }

                        {(userrole==='admin' || userrole==='teacher') &&
                        <SidebarItem isActive={routeActive('/home')} item={{
                            name: 'Задание',
                            icon: 'fas fa-home',
                            path: '/home'
                        }} aid={aid} />}

                        <SidebarItem isActive={routeActive('/tasks')} item={{
                            name: 'Задачи',
                            icon: 'icon-doc',
                            path: '/tasks'
                        }} aid={aid} />

                        <SidebarItem isActive={routeActive('/submissions')} item={{
                            name: 'Решения',
                            icon: 'far fa-file-code',
                            path: '/submissions'
                        }} aid={aid} />

                        {(userrole==='admin' || userrole==='teacher' || shouldShowStandingsToUser()) && 
                        <SidebarItem isActive={routeActive('/standing')} item={{
                            name: 'Класиране',
                            icon: 'fas fa-sort-amount-down',
                            path: '/standing'
                        }} aid={aid} />}
                        {(userrole==='admin' || userrole==='teacher') && assignment.needsregistration &&
                        <SidebarItem isActive={routeActive('/registered')} item={{
                            name: 'Регистрирани',
                            icon: 'fas fa-users',
                            path: '/registered'
                        }} aid={aid} />}
                        {(userrole==='admin' || userrole==='teacher') &&
                        <SidebarItem isActive={routeActive('/statistics')} item={{
                            name: 'Статистики',
                            icon: 'fas fa-chart-bar',
                            path: '/statistics'
                        }} aid={aid} />}
    {(assignment.id===1227 || assignment.id===1228 || assignment.id===1229) &&
        <li className={ '' }>
    <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1bXB3XzpoeSTwGF81CiHVV10D_-T27XLeGpNGlHIqpLI/edit?usp=sharing">
            <em className="fas fa-chart-bar"></em>
                Информация
                </a>
    </li>}



                    </ul>
                    { /* END sidebar nav */ }
                </nav>
            </div>
            { /* END Sidebar (left) */ }
        </aside>
    );
}


const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translations')(AssignmentSidebar));
