const AdminMenu = [
    {
        name: 'Групи',
        icon: 'fas fa-users',
        path: '/groups'
    },
    {
        name: 'Задачи',
        icon: 'icon-doc',
        path: '/tasks'
    },
    {
        name: 'Решения',
        icon: 'far fa-file-code',
        path: '/submissions'
    },
    {
        name: 'Потребители',
        icon: 'fas fa-user',
        path: '/users'
    },
    {
        name: 'Групи за достъп',
        icon: 'fas fa-user-shield',
        path: '/permissiongroups'
    },
    // {
    //     name: 'Състезания',
    //     icon: 'fas fa-code',
    //     path: '/competitions'
    // }
];

const TeacherMenu = [
    {
        name: 'Групи',
        icon: 'fas fa-users',
        path: '/groups'
    },
    {
        name: 'Задачи',
        icon: 'icon-doc',
        path: '/tasks'
    },
    {
        name: 'Решения',
        icon: 'far fa-file-code',
        path: '/submissions'
    },
    {
        name: 'Групи за достъп',
        icon: 'fas fa-user-shield',
        path: '/permissiongroups'
    },
    // {
    //     name: 'Състезания',
    //     icon: 'fas fa-code',
    //     path: '/competitions'
    // }
];

const UserMenu = [
    {
        name: 'Групи',
        icon: 'fas fa-users',
        path: '/groups'
    },
    // {
    //     name: 'Състезания',
    //     icon: 'fas fa-code',
    //     path: '/competitions'
    // }
];

const Menu = {admin: AdminMenu, teacher: TeacherMenu, user: UserMenu};

export default Menu;
