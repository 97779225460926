import React from 'react';

import Header from '../Header'
import Footer from '../Footer'
import AssignmentsSidebar from './AssignmentSidebar';
import { AssignmentProvider } from '../../../AssignmentContext';

const AssignmentBase = (props) => (
    <AssignmentProvider aid={props.aid} >
        <div className="wrapper">
            <Header />

            <AssignmentsSidebar aid={props.aid} />

            {/* <Offsidebar */}

            <section className="section-container">
                { props.children }
            </section>

            <Footer />
        </div>
    </AssignmentProvider>
)

export default AssignmentBase;
