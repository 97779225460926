
const prod = {
    REST_URL: 'https://pesho.org/api'
};

const dev = {
    REST_URL: 'http://localhost:8081/api'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
