/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.3
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Suspense, Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PageLoader from './components/Common/PageLoader';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import ReactGA from 'react-ga';

class App extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-160286036-1');
    ReactGA.pageview(window.location.pathname + window.location.search); 
    // CKEditor.editorUrl = '/ckeditor/ckeditor.js';
    // TODO: server the editor as part of this site 
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');

    return (
        <BrowserRouter basename={basename}>
          <Suspense fallback={<PageLoader/>}>
            <Routes />
            </Suspense>
        </BrowserRouter>
    );

  }
}

export default App;
