import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useRest } from './RestContext';
import useAsync from './useAsync';
import useInterval from './useInterval';

const AssignmentContext = React.createContext()

const AssignmentProvider = ({aid, children}) => {

    const json = useRest().json;
    const [shouldUpdateAssignment, setShouldUpdateAssignment] = useState(false);
    const [shouldUpdateTasks, setShouldUpdateTasks] = useState(false);

    const { value: assignment } = useAsync(json, `assignments/${aid}`, [aid, shouldUpdateAssignment]);
    const { value: tasks } = useAsync(json, `assignments/${aid}/tasks`, [aid, shouldUpdateTasks]);

    useInterval(() => {
        setShouldUpdateAssignment(!shouldUpdateAssignment);
    }, 60000);

    useInterval(() => {
        setShouldUpdateTasks(!shouldUpdateTasks);
    }, assignment && assignment.refreshtime ? assignment.refreshtime*1000 : null);

    const updateAll = () => {
        setShouldUpdateAssignment(v => !v);
        setShouldUpdateTasks(v => !v);
    }

    return (
        <AssignmentContext.Provider
            value={{
                assignment,
                tasks,
                updateAll
            }}>
            {children}
        </AssignmentContext.Provider>
    )

}

const useAssignment = () => React.useContext(AssignmentContext)

export { AssignmentProvider, useAssignment }
