import React from 'react'
import Countdown from 'react-countdown';
import { useAssignment } from '../../AssignmentContext';
import moment from 'moment';

const AssignmentCountdown = () => {
    const assignment = useAssignment().assignment;

    if (!assignment) return <div></div>;

    if (assignment.endtime == null) {
        return <div/>
    }
    const contestStartTime = moment(assignment.starttime).toDate();
    const contestEndTime = moment(assignment.endtime).toDate();

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const runningRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Край</span>;
        } else {
            if (days > 6) return <span>Край след<br/>{parseInt(days, 10)} дена</span>;
            else if (days > 0) return <span>Край след<br/>{days}д {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
            return <span>Край след<br/>{zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
        }
    };

    const notStartedRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Countdown date={contestEndTime} renderer={runningRenderer} />;
        } else {
            if (days > 6) return <span>Начало след<br/>{parseInt(days, 10)} дена</span>;
            else if (days > 0) return <span>Начало след<br/>{days}д {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
            return <span>Начало след{zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
        }
    };

    return (
        <div id="timer" style={{ color: '#b8c7ce', textAlign: 'center', fontSize: '30px' }}>
            <Countdown date={contestStartTime} renderer={notStartedRenderer}/>
        </div>
    )
}

export default AssignmentCountdown
