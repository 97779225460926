import React from 'react';
import { useAuth } from './AuthContext';
import { config } from './Constants'

const RestContext = React.createContext()

const RestProvider = ({children}) => {

    const role = useAuth().userrole;
    const token = useAuth().usertoken;
    const logout = useAuth().logout;

    const json = async (url) => {
        const response = await sendRequest(url, "json");
        return await response.json();
    }

    const blob = async (url) => {
        const response = await sendRequest(url, "blob");
        return await response.blob();
    }

    const post = async (url, formData) => {
        const response = await sendPostRequest(url, formData);
        return await response.json();
    }

    const put = async (url, formData) => {
        await sendPutRequest(url, formData);
    }

    const delete1 = async (url, formData) => {
        await sendDeleteRequest(url, formData);
    }

    const sendRequest = (url, type) => {
        return sendRequestWithToken(url, type);
    }
    
    const getURL = (url) => {
        return `${config.REST_URL}/${role}/${url}`
    }

    const sendRequestWithToken = (url, type) => {
        return fetch(getURL(url), {
            headers: {
                'Authorization': `Basic ${token}`
            },
            responseType: type
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendPostRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendPutRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'PUT',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }
    
    const sendDeleteRequest = (url, formData) => {
        return fetch(getURL(url), {
            method: 'DELETE',
            headers: {
                'Authorization': `Basic ${token}`
            },
            body: formData,
            responseType: 'json'
        }).then((response) => {
            if (response.status === 403) {
                logout();
            }
            return response;
        });
    }

    return (
        <RestContext.Provider
            value={{
                json,
                blob,
                post,
                put,
                delete1,
                sendRequestWithToken
            }}>
            {children}
        </RestContext.Provider>
    )

}

const useRest = () => React.useContext(RestContext)

export { RestProvider, useRest }
